import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Events4 = () => (
  <StaticImage
    src="../../../images/newsEvents/149797.jpg"
    alt="Jewelry fair Vicenza 2019 advance manufacturers"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default Events4
