import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Events6 = () => (
  <StaticImage
    src="../../../images/newsEvents/VO_outdoor.jpg"
    alt="Jewelry fair Vicenza 2019 advance manufacturers"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default Events6
