import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Flex, Box } from "reflexbox"
import styled from "@emotion/styled"
import WrapperContainers from "../components/WrapperContainer"
import P from "../components/Typography/P/P"
import StyledNewsHero from "../containers/NewsEvents/NewsHero"
import Events1 from "../containers/NewsEvents/Images/Events1"
import Events2 from "../containers/NewsEvents/Images/Events2"
import Events3 from "../containers/NewsEvents/Images/Events3"
import Events4 from "../containers/NewsEvents/Images/Events4"
import Events5 from "../containers/NewsEvents/Images/Events5"
import Events6 from "../containers/NewsEvents/Images/Events6"

const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
`

const GreenP = styled(P)`
  text-align: center;
  padding-top: 0.5vh;
  color: #29d9d5;
  @media (max-width: 968px) {
    width: 100%;
    padding-top: 0;
  }
`
const Date = styled.p`
  text-align: center;
  font-size: 1.6em;
  font-weight: bold;
  padding-top: 11vh;
  @media (max-width: 968px) {
    padding-top: 1vh;
  }
`
const H2Place = styled.h2`
  font-size: calc(0.6em + 1.6vw);
  color: ${({ theme }) => theme.h1};
  font-family: "OFL Sorts Mill Goudy";
  text-align: center;
  font-weight: bold;
  @media (max-width: 968px) {
    font-size: calc(1em + 1.6vw);
  }
`
const H2Green = styled.span`
  color: #29d9d5;
`
const Location = styled.h4`
  color: #29d9d5;
  font-size: calc(0.6em + 0.9vw);
  text-align: center;
  @media (max-width: 968px) {
    font-size: calc(1em + 0.9vw);
  }
`
const Description = styled.p`
  font-size: calc(0.6em + 0.6vw);
  padding: 0 5vw;
  @media (max-width: 968px) {
    font-size: calc(1em + 0.6vw);
  }
`
const BgDate = styled(Box)`
  background-color: rgba(41, 217, 213, 0.16);
`
const DesCenter = styled(Description)`
  text-align: center;
`
const NewsEvents = () => (
  <Layout>
    <Seo title="News Events jewelry exhibitions" />
    <StyledNewsHero />
    <Section>
      <Flex flexWrap="wrap">
        <Box width={[1]} order={[1]}>
          <GreenP>
            Our sales team attends the following jewelry exhibitions annually.
            <br />
            We would love to welcome you to our booth. Dates will be updated
            regularly, please bookmark this page!
          </GreenP>
        </Box>
      </Flex>
      <Flex flexWrap="wrap" p={[2, 3, 3]}>
        <Box width={[1, 1, 2 / 6]} order={[4, 4, 2]}>
          <Events1 />
        </Box>
        <Box width={[1, 1, 3 / 6]} order={[3, 3, 3]} p={[2, 3, 3]}>
          <H2Place>
            <H2Green>Vicenza Oro</H2Green>
            <br />
            The Jewellery Boutique Show
          </H2Place>
          <Location>
            Location: Fiera di Vicenza
            <br />
            Vicenza Italy
          </Location>
          <Description>
            Each year, we kick the year off in style in Vicenza Italy.
            <br />
            VicenzaOro, held twice per year, is the largest jewelry trade fair
            in Europe and one of the largest in the world.
          </Description>
        </Box>
        <BgDate width={[1, 1, 1 / 6]} order={[2, 2, 4]}>
          <Date>
            January 20-24th 2023 Booth #154 at Hall 3.1
          </Date>
        </BgDate>
      </Flex>{" "}
      <Flex flexWrap="wrap" p={[2, 3, 3]}>
        <Box width={[1, 1, 2 / 6]} order={[5, 5, 7]}>
          <Events2 />
        </Box>
        <Box width={[1, 1, 3 / 6]} order={[6, 6, 6]} p={[2, 3, 3]}>
          <H2Place>Hong Kong Jewellery &amp; Gem Fair</H2Place>
          <Location>
            Location: Kong Hong Kong Convention &amp; Exhibition Centre
            <br />
            Hong Kong
          </Location>
          <Description>
            One of the most exciting jewelry exhibitions of the year is at the
            Hong Kong Jewellery &amp; Gem Fair. We never miss it as it’s one of
            the biggest and most important jewelry exhibitions in Asia!
          </Description>
        </Box>
        <BgDate width={[1, 1, 1 / 6]} order={[7, 7, 5]}>
          <Date>
            March 1st-5th 2023 Booth # A13-16, Hall 5C<br />
          </Date>
        </BgDate>
      </Flex>
      <Flex flexWrap="wrap" p={[2, 3, 3]}>
        <Box width={[1, 1, 2 / 6]} order={[10, 10, 8]}>
          <Events3 />
        </Box>
        <Box width={[1, 1, 3 / 6]} order={[9, 9, 9]} p={[2, 3, 3]}>
          <H2Place>Jewellery and Gem Asean Bangkok </H2Place>
          <Location>
            Location: Queen Sirikit National Convention Centre
            <br />
            Bangkok Thailand
          </Location>
          <Description>
            Exhibiting at the first edition of Jewellery and Gem Asean Bangkok 2023.
          </Description>
        </Box>
        <BgDate width={[1, 1, 1 / 6]} order={[8, 8, 10]}>
          <Date>

            April  26th-29th 2023 Booth # Q31<br />
          </Date>
        </BgDate>
      </Flex>

      <Flex flexWrap="wrap" p={[2, 3, 3]}>
        <Box width={[1, 1, 2 / 6]} order={[11, 11, 13]}>
          <Events4 />
        </Box>
        <Box width={[1, 1, 3 / 6]} order={[12, 12, 12]} p={[2, 3, 3]}>
          <H2Place>Bangkok Gems &amp; Jewellery Fair</H2Place>
          <Location>
            Location: Queen Sirikit National Convention Center
            <br />
            Bangkok Thailand
          </Location>

        </Box>
        <BgDate width={[1, 1, 1 / 6]} order={[13, 13, 11]}>
          <Date>
            September 6th-10th 2023 <br /> Booth # P42 & Q41
          </Date>
        </BgDate>
      </Flex>
      <Flex flexWrap="wrap" p={[2, 3, 3]}>
        <Box width={[1, 1, 2 / 6]} order={[16, 16, 14]}>
          <Events6 />
        </Box>
        <Box width={[1, 1, 3 / 6]} order={[15, 15, 15]} p={[2, 3, 3]}>
          <H2Place>
            <H2Green>Vicenza Oro</H2Green>
            <br />
            The Jewellery Boutique Show
          </H2Place>
          <Location>
            Location: Fiera di Vicenza
            <br />
            Vicenza Italy
          </Location>
          <Description>
            At our last jewellery show for the year in Europe where we present new
            designs predicting trends of the upcoming year, we also showcase all
            of our bestsellers of the current year.
          </Description>
        </Box>
        <BgDate width={[1, 1, 1 / 6]} order={[14, 14, 16]}>
          <Date>
            September 8th-12th 2023 <br /> Booth # 154 at Hall 3.1
          </Date>
        </BgDate>
      </Flex>
      <Flex flexWrap="wrap" p={[2, 3, 3]}>
        <Box width={[1, 1, 2 / 6]} order={[17, 17, 19]}>
          <Events5 />
        </Box>
        <Box width={[1, 1, 3 / 6]} order={[18, 18, 18]} p={[2, 3, 3]}>
          <H2Place>Gem &amp; Jewellery World Hong Kong</H2Place>
          <Location>
            Location: Kong Hong Kong Convention &amp; Exhibition Centre
            <br />
            Hong Kong
          </Location>
        </Box>
        <BgDate width={[1, 1, 1 / 6]} order={[19, 19, 17]}>
          <Date>
            September 20th-24th 2023 <br /> Booth #5C202 Hall 5C
          </Date>
        </BgDate>
      </Flex>

    </Section>
  </Layout>
)

export default NewsEvents
