import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Events1 = () => (
  <StaticImage
    src="../../../images/newsEvents/2019_Vicenza_Jan2.JPG"
    alt="Jewelry fair Vicenza 2019 advance manufacturers"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default Events1
